import React from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const introImages = [
  { src: "/1.png", alt: "Image 1" },
  { src: "/2.png", alt: "Image 2" },
  { src: "/3.png", alt: "Image 3" },
  { src: "/4.png", alt: "Image 4" },
];

const IntroSectionPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className="container mx-auto py-4 px-4">
      <Slider {...settings}>
        {introImages.map((image, index) => (
          <motion.div
            key={index}
            className="relative flex flex-col items-center" // Flex column to stack image and text vertically
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            style={{ height: '60vh', maxHeight: '600px' }} // Responsive height with max constraint
          >
            <div className="w-full h-full relative">
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-cover" // Ensures image covers the container
                style={{ objectFit: 'cover' }} // Ensures image covers container
              />
            </div>
            <div className="p-4 bg-black bg-opacity-50 text-white text-center mt-2">
              <h2 className="text-xl font-bold">Ignite Your Vision: Build, Innovate, Succeed!</h2>
              <p className="mt-2">
                At Orbit 25, we are dedicated to transforming your dreams into tangible realities. Our mission is to empower your ideas with innovative engineering and impeccable craftsmanship. We are committed to excellence, ensuring your visions come to life through groundbreaking solutions. Join us on a journey where code evolves into innovation, and success is redefined. Experience the future of technology with us, and watch your triumphs reach unparalleled heights.
              </p>
            </div>
          </motion.div>
        ))}
      </Slider>
    </div>
  );
};

export default IntroSectionPage;
