import React from "react";
import { motion } from "framer-motion";

const BusinessDevelopment = () => {
  const fadeInUp = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
  };

  const staggerContainer = {
    initial: {},
    animate: {
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  return (
    <div className="container mx-auto py-12 px-4">
      <motion.h1
        className="text-5xl font-extrabold mb-8 text-center text-green-700 tracking-wide"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        Business Technology Department
      </motion.h1>

      <motion.p
        className="text-xl text-gray-700 mb-12 text-center leading-relaxed max-w-2xl mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        We assist in business development by offering strategic planning, market
        analysis, and innovative solutions to help your business grow and
        succeed.
      </motion.p>

      {/* Services Section */}
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12"
        variants={staggerContainer}
        initial="initial"
        animate="animate"
      >
        {[
          {
            title: "Strategic Planning",
            description:
              "Our strategic planning services help you set clear goals and develop a roadmap to achieve them.",
            color: "from-green-400 via-blue-500 to-purple-600",
          },
          {
            title: "Market Analysis",
            description:
              "We provide in-depth market analysis to identify opportunities and challenges in your industry.",
            color: "from-pink-500 via-red-500 to-yellow-500",
          },
          {
            title: "Innovative Solutions",
            description:
              "Our innovative solutions are designed to address your unique business needs and drive growth.",
            color: "from-indigo-500 via-purple-500 to-pink-500",
          },
        ].map((service, index) => (
          <motion.div
            key={index}
            className="bg-gradient-to-r p-1 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
            whileHover={{ rotate: [0, 2, -2, 0] }}
            variants={fadeInUp}
          >
            <div className={`bg-white p-6 rounded-lg`}>
              <h2
                className={`text-2xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r ${service.color}`}
              >
                {service.title}
              </h2>
              <p className="text-gray-600">{service.description}</p>
            </div>
          </motion.div>
        ))}
      </motion.div>

      {/* New Section */}
      <div className="mt-16 bg-gradient-to-r from-green-50 via-white to-green-50 py-16 rounded-lg shadow-inner">
        <motion.h2
          className="text-4xl font-bold mb-8 text-center text-green-600"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: "backOut" }}
        >
          Business Development Services
        </motion.h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12"
          variants={staggerContainer}
          initial="initial"
          animate="animate"
        >
          {[
            {
              title: "Increasing Sales",
              description:
                "Our team identifies and targets your market, creates a sales plan, and executes it successfully.",
            },
            {
              title: "Strategic Partnerships",
              description:
                "We explore partnerships that can help your business grow on a global level.",
            },
            {
              title: "Marketing Research",
              description:
                "We help in conducting market research, developing, and executing marketing plans.",
            },
            {
              title: "Business Feasibility Study",
              description:
                "We conduct thorough business feasibility studies and identify key factors related to your project.",
            },
            {
              title: "Business Planning",
              description:
                "We provide guidance and support throughout the planning process.",
            },
            {
              title: "Digital Marketing",
              description:
                "We cover search engine optimization, online advertising, social media marketing, and more.",
            },
          ].map((service, index) => (
            <motion.div
              key={index}
              className="bg-gradient-to-b from-white to-gray-100 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
              whileHover={{ rotate: [0, 2, -2, 0] }}
              variants={fadeInUp}
            >
              <h2 className="text-2xl font-bold mb-4 text-green-600">
                {service.title}
              </h2>
              <p className="text-gray-600">{service.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>

      {/* Our Approach Section */}
      <motion.div
        className="mt-16"
        variants={fadeInUp}
        initial="initial"
        animate="animate"
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <h2 className="text-4xl font-bold mb-8 text-center text-green-600">
          Our Approach
        </h2>
        <ul className="list-disc list-inside text-gray-700 space-y-4 text-lg">
          <motion.li whileHover={{ x: 10 }}>Comprehensive market research</motion.li>
          <motion.li whileHover={{ x: 10 }}>Customized business strategies</motion.li>
          <motion.li whileHover={{ x: 10 }}>Collaborative and transparent process</motion.li>
          <motion.li whileHover={{ x: 10 }}>Focus on sustainable growth</motion.li>
        </ul>
      </motion.div>

      {/* Testimonials Section */}
      <div className="mt-16 bg-green-50 py-16 rounded-lg shadow-inner">
        <motion.h2
          className="text-4xl font-bold mb-8 text-center text-green-600"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: "backOut" }}
        >
          What Our Clients Say
        </motion.h2>
        <div className="flex flex-col md:flex-row justify-around items-center space-y-12 md:space-y-0">
          {[
            {
              text: `"The strategic planning and market analysis provided by this team were top-notch. Our business has seen significant growth!"`,
              name: "Jane Doe, CEO of Company",
            },
            {
              text: `"Their innovative solutions were exactly what we needed to overcome our business challenges."`,
              name: "John Smith, Marketing Director",
            },
          ].map((testimonial, index) => (
            <motion.div
              key={index}
              className="text-center max-w-sm mx-auto"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.3, duration: 0.8, ease: "easeOut" }}
            >
              <p className="text-gray-600 italic leading-relaxed">{testimonial.text}</p>
              <p className="text-green-600 mt-4 font-semibold">{testimonial.name}</p>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Call-to-Action Banner */}
      <motion.div
        className="mt-16 bg-green-600 py-12 text-center rounded-lg shadow-lg"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <h2 className="text-4xl font-bold text-white">
          Ready to Grow Your Business?
        </h2>
        <p className="text-xl text-green-100 mt-4 max-w-2xl mx-auto">
          Contact us today to discuss how we can help you achieve your business
          goals.
        </p>
        <motion.button
          className="mt-8 bg-white text-green-600 py-3 px-8 rounded-lg font-semibold hover:bg-gray-200 transition-transform transform hover:scale-105 duration-300"
          whileHover={{ scale: 1.1 }}
        >
          Contact Us
        </motion.button>
      </motion.div>

      {/* FAQ Section */}
      <motion.div
        className="mt-16"
        variants={fadeInUp}
        initial="initial"
        animate="animate"
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <h2 className="text-4xl font-bold mb-8 text-center text-green-600">
          Frequently Asked Questions
        </h2>
        <div className="max-w-2xl mx-auto space-y-8">
          {[
            {
              question: "What industries do you specialize in?",
              answer:
                "We work with a wide range of industries, including technology, healthcare, retail, and more.",
            },
            {
              question: "How do you approach business development?",
              answer:
                "Our approach is collaborative and data-driven. We work closely with our clients to develop strategies that are tailored to their specific needs and goals.",
            },
            {
              question: "What makes your services unique?",
              answer:
                "Our combination of market expertise, innovative solutions, and a focus on sustainable growth sets us apart from the competition.",
            },
          ].map((faq, index) => (
            <motion.div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg"
              whileHover={{ rotate: [0, 1, -1, 0] }}
              variants={fadeInUp}
            >
              <h3 className="text-xl font-semibold text-green-600">
                {faq.question}
              </h3>
              <p className="text-gray-600 mt-2">{faq.answer}</p>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default BusinessDevelopment;
