import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-gray-800 via-gray-900 to-black text-white p-8 shadow-lg relative z-50">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          <div className="flex flex-col justify-center items-center md:items-start md:col-span-2">
            <img
              src="logo.png"
              alt="Orbit 25 Logo"
              className="h-20 w-64 mb-4"
            />
            <p className="text-gray-400 text-sm">
              Orbit25 is a leading technology company providing top-notch
              solutions in software development, web design, and tech
              consultancy. Our mission is to bring innovation and efficiency to
              businesses across the globe.
            </p>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Top Trends</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {/* Trends list */}
              <ul className="list-none">
                <li className="text-gray-400 mb-2">Front-End Developers</li>
                <li className="text-gray-400 mb-2">Full-Stack Developers</li>
                <li className="text-gray-400 mb-2">Node.js Developers</li>
                <li className="text-gray-400 mb-2">JavaScript Developers</li>
                <li className="text-gray-400 mb-2">React Developers</li>
                <li className="text-gray-400 mb-2">.NET Developers</li>
                <li className="text-gray-400 mb-2">Java Developers</li>
                <li className="text-gray-400 mb-2">PHP Developers</li>
                <li className="text-gray-400 mb-2">C/C++ Developers</li>
                <li className="text-gray-400 mb-2">C# Developers</li>
              </ul>
              <ul className="list-none">
                <li className="text-gray-400 mb-2">Ruby Developers</li>
                <li className="text-gray-400 mb-2">Unity3D Developers</li>
                <li className="text-gray-400 mb-2">iOS Developers</li>
                <li className="text-gray-400 mb-2">Android Developers</li>
                <li className="text-gray-400 mb-2">nopCommerce Developers</li>
                <li className="text-gray-400 mb-2">Drupal Developers</li>
                <li className="text-gray-400 mb-2">SQL Developers</li>
                <li className="text-gray-400 mb-2">QA Engineers</li>
                <li className="text-gray-400 mb-2">UI/UX Designers</li>
              </ul>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Get In Touch</h3>
            <p className="text-gray-400 mb-2">Mobile: +880 1645636229</p>
            <p className="text-gray-400 mb-2">
              Email:{" "}
              <a
                href="mailto:contact@orbit25.com"
                className="text-gray-300 hover:text-white"
              >
                contact@orbit25.com
              </a>
            </p>
            <p className="text-gray-400 mb-2">
              Address: 153/1 Moishanbari Road, kuratoli, kuril, Dhaka-1229
            </p>
            {/* Social Media Icons */}
            <div className="flex mt-4 space-x-4">
              <a href="https://www.facebook.com/orbit25.official/" className="hover:text-blue-600">
                <FaFacebookF size={30} color="#3b5998" />
              </a>
              {/* <a href="#" className="hover:text-blue-400">
                <FaTwitter size={30} color="#1DA1F2" />
              </a> */}
              <a href="https://www.linkedin.com/company/orbit25" className="hover:text-blue-700">
                <FaLinkedinIn size={30} color="#0077B5" />
              </a>
              {/* <a href="#" className="hover:text-pink-500">
                <FaInstagram size={30} color="#E1306C" />
              </a> */}
            </div>
            {/* Embedded Map */}
            <div className="mt-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d662.5207191781029!2d90.42393916410674!3d23.81946218624858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c700236311bd%3A0x242b897e7ebe3e09!2sOrbit%2025!5e0!3m2!1sen!2sbd!4v1724781526693!5m2!1sen!2sbd"
                width="100%"
                height="200"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                title="Orbit25 Location"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="text-center border-t border-gray-700 pt-4">
          <p>&copy; {new Date().getFullYear()} Orbit25. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
