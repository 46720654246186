import React from "react";

const LocalSEO = () => {
  return (
    <div className="container mx-auto py-12 px-4">
      <h1 className="text-4xl font-bold mb-6 text-center text-teal-600">Local SEO</h1>
      <p className="text-lg text-gray-700 mb-8 text-center">
        Optimize your online presence with our Local SEO services, ensuring your business appears in local search results and attracts more local customers.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
        <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300">
          <h2 className="text-2xl font-semibold mb-4">Local Listings</h2>
          <p className="text-gray-600">Ensure your business is accurately listed on all major local directories and search engines.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300">
          <h2 className="text-2xl font-semibold mb-4">On-Page SEO</h2>
          <p className="text-gray-600">Optimize your website's content and structure to improve local search visibility.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300">
          <h2 className="text-2xl font-semibold mb-4">Customer Reviews</h2>
          <p className="text-gray-600">Manage and encourage positive customer reviews to boost your local reputation.</p>
        </div>
      </div>

      <div className="mt-12 mb-12">
        <h2 className="text-3xl font-bold mb-6 text-center text-teal-600">Our Approach</h2>
        <ul className="list-disc list-inside text-gray-700 space-y-4">
          <li>Comprehensive local keyword research</li>
          <li>Optimization of Google My Business profile</li>
          <li>Local link building strategies</li>
          <li>Regular performance tracking and reporting</li>
        </ul>
      </div>

      <div className="bg-gray-100 p-8 rounded-lg shadow-lg mb-12">
        <h2 className="text-3xl font-bold mb-6 text-center text-teal-600">Success Stories</h2>
        <div className="flex flex-wrap gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg flex-1 min-w-[300px]">
            <h3 className="text-xl font-semibold mb-4">Client A</h3>
            <p className="text-gray-600">"Our local search visibility has dramatically improved. The team’s expertise in local SEO is exceptional."</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex-1 min-w-[300px]">
            <h3 className="text-xl font-semibold mb-4">Client B</h3>
            <p className="text-gray-600">"The increase in local traffic and customer engagement has been remarkable. Highly recommend their services!"</p>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 p-8 rounded-lg shadow-lg mb-12">
        <h2 className="text-3xl font-bold mb-6 text-center text-teal-600">Detailed Service Breakdown</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold mb-4">Local Listings Management</h3>
            <p className="text-gray-600">We handle the creation, optimization, and maintenance of your local business listings to ensure consistency and accuracy.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold mb-4">On-Page SEO Optimization</h3>
            <p className="text-gray-600">Our on-page SEO services include keyword optimization, meta tags, and local content strategies to enhance your website’s local relevance.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold mb-4">Review Management</h3>
            <p className="text-gray-600">We help you manage and respond to customer reviews, encouraging positive feedback and addressing any concerns promptly.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold mb-4">Local Link Building</h3>
            <p className="text-gray-600">Build local authority with targeted link building strategies that enhance your business’s online reputation and search rankings.</p>
          </div>
        </div>
      </div>

      <div className="mt-12 text-center">
        <button className="bg-teal-600 text-white py-2 px-4 rounded-lg hover:bg-teal-700 transition duration-300">
          Get Started
        </button>
      </div>
    </div>
  );
};

export default LocalSEO;
