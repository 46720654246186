import React from "react";
import LatestTechSection from "./LatestTechSection";
import BenefitsSection from "./BenefitsSection";
import IntroSectionPage from "./IntroSectionPage";

const StatCard = ({ iconSrc, number, label }) => (
  <div className="flex flex-col items-center text-center font-semibold ">
    <img src={iconSrc} alt={label} className="w-16 h-16 mb-4" />
    <p className="text-4xl font-bold text-blue-500">{number}</p>
    <p className="text-lg text-gray-700">{label}</p>
  </div>
);

const Section = ({
  title,
  children,
  bgColor = "",
  textColor = "text-gray-800",
}) => (
  <section className={`${bgColor} ${textColor}`}>
    <h2 className="text-4xl font-bold mb-6 text-center">{title}</h2>
    {children}
  </section>
);

const Button = ({ label }) => (
  <button className="mt-4 bg-white border-2 border-gray-700 text-gray-700 py-2 px-4 rounded-lg shadow-md hover:bg-gray-700 hover:text-white transition-colors duration-300">
    {label}
  </button>
);

const PartnerCard = ({ name, imgSrc }) => (
  <div className="flex items-center p-6 bg-gray-50 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
    <img src={imgSrc} alt={name} className="w-24 h-12 object-cover mr-4" />
    <p className="text-gray-700">{name}</p>
  </div>
);

const IndustryCard = ({ name, iconSrc }) => (
  <div className=" hover:shadow-xl transition-shadow duration-300 text-center text-white">
    <img src={iconSrc} alt={name} className="w-16 h-16 mx-auto mb-4" />
    <h3 className="text-2xl font-bold mb-4">{name}</h3>
  </div>
);

const TransformedCard = ({ title, description, imgSrc }) => (
  <div className="p-6 bg-gray-50 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col justify-between">
    <div>
      <img
        src={imgSrc}
        alt={title}
        className="w-full h-48 object-cover rounded-lg mb-4"
      />
      <h3 className="text-2xl font-semibold mb-4 text-gray-700">{title}</h3>
      <p className="text-gray-600">{description}</p>
      <p className="text-gray-600">Developed by Orbit 25</p>
    </div>
    <Button label="Check Out" />
  </div>
);

const StatsSection = () => (
  <Section>
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
        {[
          { iconSrc: "/t.png", number: "5+", label: "Tech Partners" },
          { iconSrc: "/co.png", number: "4+", label: "Years of Experience" },
          { iconSrc: "/pr.png", number: "5+", label: "Countries Served" },
          { iconSrc: "/coder.png", number: "10+", label: "IT Professionals" },
          { iconSrc: "/check.png", number: "7+", label: "Projects Completed" },
        ].map((stat, index) => (
          <StatCard key={index} {...stat} />
        ))}
      </div>
    </div>
  </Section>
);

const Main = () => (
  <main className="p-6 space-y-12 bg-gray-200 text-gray-800">
    <IntroSectionPage />
    <StatsSection />

    <BenefitsSection />

    {/* <Section title="Technology Partners">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {[
          { name: "Partner 1", imgSrc: "https://via.placeholder.com/100x50" },
          { name: "Partner 2", imgSrc: "https://via.placeholder.com/100x50" },
          { name: "Partner 3", imgSrc: "https://via.placeholder.com/100x50" },
          { name: "Partner 4", imgSrc: "https://via.placeholder.com/100x50" },
        ].map((partner, index) => (
          <PartnerCard key={index} {...partner} />
        ))}
      </div>
    </Section> */}
    <Section title="Our Industry Expertise">
      <div className="container mx-auto bg-indigo-950 p-6 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-8">
          {[
            { name: "Fintech", iconSrc: "/fin.png" },
            { name: "Healthcare", iconSrc: "/heart.png" },
            { name: "Telco", iconSrc: "/tel.png" },
            { name: "E-com", iconSrc: "/ecom.png" },
            { name: "Real Estate", iconSrc: "/realestate.png" },
            { name: "Automotive", iconSrc: "/automotive.png" },
            { name: "Software", iconSrc: "/software.png" },
            { name: "Startup", iconSrc: "/startup.png" },
            { name: "Education", iconSrc: "/education.png" },
            { name: "Retail", iconSrc: "/retail.png" },
            { name: "Non-Profit", iconSrc: "/nonprofit.png" },
            { name: "Miscellaneous", iconSrc: "/miscellaneous.png" },
          ].map((industry, index) => (
            <IndustryCard key={index} {...industry} />
          ))}
        </div>
      </div>
    </Section>
    <Section title="Transformed Tech Solutions">
      <p className="text-lg text-center text-gray-700 mb-8">
        Catalysts of Change: Revealing the Remarkable Stories of Businesses that
        Have Achieved Monumental Transformation Through Seamless Integration of
        Technology, Revolutionizing Their Operations and Shaping a New Digital
        Paradigm
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {[
          {
            title: "Category: Website Development",
            description: "Admin Dashboard",
            imgSrc: "/b4.png",
          },
          {
            title: "Category: Mobile Apps",
            description: "OTA Platform",
            imgSrc: "/andro.jpg",
          },
          {
            title: "Software Development",
            description: "AI Chatbot",
            imgSrc: "/b8.png",
          },
          {
            title: "Category: E-Commerce",
            description: "'Hypex' A UK e-commerce based company",
            imgSrc: "/Capture11.PNG",
          },
        ].map((item, index) => (
          <TransformedCard key={index} {...item} />
        ))}
      </div>
      <div className="flex justify-center mt-8">
        <Button label="See More" />
      </div>
    </Section>

    <LatestTechSection />
  </main>
);

export default Main;
