import React from "react";

const DigitalAdvertising = () => {
  return (
    <div className="container mx-auto py-12 px-4">
      <h1 className="text-4xl font-bold mb-6 text-center text-red-600">Digital Advertising</h1>
      <p className="text-lg text-gray-700 mb-8 text-center">
        Our digital advertising services help you reach a broader audience with targeted campaigns that maximize your return on investment.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
        <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300">
          <h2 className="text-2xl font-semibold mb-4">Social Media Marketing</h2>
          <p className="text-gray-600">Engage with your audience on social media platforms with our expert marketing strategies.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300">
          <h2 className="text-2xl font-semibold mb-4">Search Engine Optimization</h2>
          <p className="text-gray-600">Improve your website's visibility on search engines and attract more organic traffic.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300">
          <h2 className="text-2xl font-semibold mb-4">Pay-Per-Click Advertising</h2>
          <p className="text-gray-600">Maximize your ROI with our targeted PPC campaigns that drive immediate results.</p>
        </div>
      </div>

      <div className="mt-12 mb-12">
        <h2 className="text-3xl font-bold mb-6 text-center text-red-600">Our Expertise</h2>
        <ul className="list-disc list-inside text-gray-700 space-y-4">
          <li>Comprehensive campaign management</li>
          <li>Data-driven strategies</li>
          <li>Creative ad design</li>
          <li>Performance tracking and optimization</li>
        </ul>
      </div>

      <div className="bg-gray-100 p-8 rounded-lg shadow-lg mb-12">
        <h2 className="text-3xl font-bold mb-6 text-center text-red-600">Success Stories</h2>
        <div className="flex flex-wrap gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg flex-1 min-w-[300px]">
            <h3 className="text-xl font-semibold mb-4">Client A</h3>
            <p className="text-gray-600">"The digital advertising campaign significantly boosted our online presence and sales. Highly recommend!"</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex-1 min-w-[300px]">
            <h3 className="text-xl font-semibold mb-4">Client B</h3>
            <p className="text-gray-600">"Exceptional service with great ROI. The targeted ads really helped in reaching our audience effectively."</p>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 p-8 rounded-lg shadow-lg mb-12">
        <h2 className="text-3xl font-bold mb-6 text-center text-red-600">Contact Us</h2>
        <form className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-gray-700 mb-2">Name</label>
            <input id="name" type="text" className="w-full p-3 border border-gray-300 rounded-lg" required />
          </div>
          <div>
            <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
            <input id="email" type="email" className="w-full p-3 border border-gray-300 rounded-lg" required />
          </div>
          <div>
            <label htmlFor="message" className="block text-gray-700 mb-2">Message</label>
            <textarea id="message" rows="4" className="w-full p-3 border border-gray-300 rounded-lg" required></textarea>
          </div>
          <div className="text-center">
            <button type="submit" className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700 transition duration-300">
              Send Message
            </button>
          </div>
        </form>
      </div>

      <div className="mt-12 text-center">
        <button className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700 transition duration-300">
          Get Started
        </button>
      </div>
    </div>
  );
};

export default DigitalAdvertising;
