import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Career = () => {
  const navigate = useNavigate();

  const jobs = [
    {
      pagedescription:
        "We are looking for a passionate and experienced Software Engineer to join our dynamic team. As a Software Engineer at orbit25, you will be working on cutting-edge technology and contributing to innovative projects.",
      title: "Software Engineer",
      location: "Remote",
      type: "Full-time",
      experience: "3+ years",
      salary: "Competitive",
      vacancies: 5,
      description:
        "We're looking for a skilled software engineer to help develop and maintain our cutting-edge products.",
      link: "/applyjob",
      experienceRequirements: [
        "Proven experience in software development with a robust project portfolio.",
        "Proficiency in programming languages such as Golang, Python (Flask/Django), or Typescript/JavaScript.",
        "Experience with web technologies, including HTML, CSS, and frameworks like React or Angular.",
        "Experience with RDBMS and No SQL.",
        "Knowledge of software development methodologies and best practices.",
        "Strong problem-solving and analytical skills.",
        "Excellent communication and teamwork abilities.",
        "Quick adaptability to new technologies and a continuous learning mindset.",
        "Knowledge on CI/CD and Code Coverages is a plus.",
      ],
      qualifications: [
        "Bachelor’s degree in Computer Science or related field.",
        "3+ years of relevant experience in software development.",
        "Proficiency in JavaScript, Python, or Java.",
        "Experience with web development frameworks like React or Angular.",
        "Strong understanding of software development best practices.",
      ],
      responsibilities: [
        "Design, develop, and maintain software applications.",
        "Collaborate with cross-functional teams to define and implement new features.",
        "Write clean, efficient, and well-documented code.",
        "Conduct code reviews and provide constructive feedback.",
        "Troubleshoot and debug software issues.",
        "Stay up-to-date with industry trends and technologies.",
      ],
      positionOverview: [
        "Work on a variety of projects that utilize cutting-edge technology.",
        "Contribute to the development and enhancement of our software products.",
        "Collaborate with a team of experienced engineers in a dynamic environment.",
      ],
    },
    {
      pagedescription: "We are looking for a talented Product Manager.",
      title: "Product Manager",
      location: "Remote",
      type: "Full-time",
      experience: "1+ years",
      salary: "Competitive",
      vacancies: 3,
      description:
        "Seeking a Product Manager to lead cross-functional teams and drive product vision.",
      link: "/applyjob",
      experienceRequirements: [
        "Experience in product management or related field.",
        "Strong understanding of market trends and customer needs.",
        "Excellent leadership and communication skills.",
        "Ability to work with cross-functional teams.",
        "Proven track record of successful product launches.",
      ],
      qualifications: [
        "Bachelor’s degree in Business Administration or related field.",
        "1+ years of experience in product management.",
        "Strong analytical and problem-solving skills.",
        "Experience with product lifecycle management.",
      ],
      responsibilities: [
        "Lead cross-functional teams to deliver product goals.",
        "Define product vision and strategy.",
        "Conduct market research and analyze customer needs.",
        "Manage product lifecycle from conception to launch.",
        "Work closely with engineering and design teams.",
      ],
      positionOverview: [
        "Lead and drive product initiatives and strategy.",
        "Work in a collaborative environment with other departments.",
        "Ensure alignment of product goals with business objectives.",
      ],
    },
    {
      pagedescription:
        "We are looking for a talented UX Designer to join our team.",
      title: "UX Designer",
      location: "Remote",
      type: "Full-time",
      experience: "3+ years",
      salary: "Competitive",
      vacancies: 2,
      description:
        "We need a creative UX Designer to enhance user experience across all our products.",
      link: "/applyjob",
      experienceRequirements: [
        "Experience in UX/UI design with a strong portfolio.",
        "Proficiency in design tools such as Sketch, Figma, or Adobe XD.",
        "Understanding of user-centered design principles.",
        "Experience with usability testing and user research.",
        "Strong problem-solving skills and creativity.",
      ],
      qualifications: [
        "Bachelor’s degree in Design, Human-Computer Interaction, or related field.",
        "3+ years of experience in UX/UI design.",
        "Strong portfolio showcasing UX design projects.",
        "Proficiency in design tools like Sketch, Figma, or Adobe XD.",
      ],
      responsibilities: [
        "Design user-centered interfaces and interactions.",
        "Conduct user research and usability testing.",
        "Collaborate with product managers and developers.",
        "Create wireframes, prototypes, and high-fidelity designs.",
        "Advocate for the user experience throughout the product lifecycle.",
      ],
      positionOverview: [
        "Work closely with product and engineering teams to design engaging user interfaces.",
        "Enhance user experience across multiple products.",
        "Apply UX principles and best practices to all design work.",
      ],
    },
  ];

  const handleApplyClick = (job) => {
    navigate(job.link, {
      state: {
        ...job,
        experienceRequirements: job.experienceRequirements,
        qualifications: job.qualifications,
        responsibilities: job.responsibilities,
        positionOverview: job.positionOverview,
      },
    });
  };

  return (
    <div className="bg-gray-100 min-h-screen p-4 sm:p-8 overflow-x-hidden">
      <div className=" mx-auto">
        {/* Banner Section */}
        <motion.div
          className="relative bg-cover bg-center text-white rounded-lg"
          style={{
            backgroundImage: "url('/customsoftware.jpg')", // Replace with your image path
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
          }}
          role="banner"
          aria-label="Good People & Great Culture"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
              <motion.h1
                className="text-3xl sm:text-4xl font-bold text-center px-4"
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.8 }}
              >
                Good People & Great Culture Blend Great Things
              </motion.h1>
            </div>
          </div>
          <div className="h-[60vh] sm:h-[80vh] lg:h-[90vh]" />
        </motion.div>

        {/* Service Cards Section */}
        <div className="relative">
          <motion.div
            className="relative z-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4 -mt-16"
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: {
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, staggerChildren: 0.2 },
              },
            }}
          >
            {[
              {
                title: "Innovation",
                description:
                  "We foster a culture of continuous innovation to stay ahead of the curve.",
              },
              {
                title: "Collaboration",
                description:
                  "Collaboration is at the heart of everything we do. Together, we achieve more.",
              },
              {
                title: "Growth",
                description:
                  "We empower our team members to grow both personally and professionally.",
              },
            ].map((card, index) => (
              <motion.div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg transition-shadow duration-300 text-gray-900 flex flex-col items-center"
                role="article"
                aria-labelledby={card.title.replace(/\s+/g, "-").toLowerCase()}
                whileHover={{
                  scale: 1.05,
                  backgroundColor: "#E0F2FE",
                }}
              >
                <h2
                  id={card.title.replace(/\s+/g, "-").toLowerCase()}
                  className="text-xl sm:text-2xl font-semibold mb-4 text-indigo-800 text-center"
                >
                  {card.title}
                </h2>
                <p className="text-gray-600 text-center">{card.description}</p>
              </motion.div>
            ))}
          </motion.div>
        </div>
        {/* Our Team is our Strength Section */}
        <motion.div
          className="mt-12 sm:mt-24 flex flex-col-reverse lg:flex-row items-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          {/* Image */}
          <div className="w-full lg:w-1/2 lg:pr-8 mb-8 lg:mb-0">
            <img
              src="bis.png"
              alt="Our Team"
              className="w-full h-auto rounded-lg shadow-lg max-w-full"
            />
          </div>

          {/* Text Content */}
          <div className="w-full lg:w-1/2 lg:pl-8">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800 mb-4 sm:mb-6">
              Our Team is our Strength
            </h2>
            <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4 sm:mb-6">
              At orbit25, we believe that our team is our greatest asset. Each
              member brings unique skills, experiences, and perspectives that
              drive our success.
            </p>
            <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-8">
              We are proud of our diverse and talented team, and we strive to
              create an environment where everyone feels valued and motivated to
              do their best work.
            </p>
          </div>
        </motion.div>

        {/* Why Work at orbit25 Section */}
        <motion.div
          className="mt-12 sm:mt-24 flex flex-col-reverse lg:flex-row items-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          {/* Text Content */}
          <div className="w-full lg:w-1/2 lg:pr-8 mb-8 lg:mb-0">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800 mb-4 sm:mb-6">
              Why Work at orbit25
            </h2>
            <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4 sm:mb-6">
              At orbit25, we offer more than just a job. We offer the
              opportunity to be part of a dynamic team that is making a real
              impact. Here are some reasons to join us:
            </p>
            <ul className="list-disc pl-5 text-sm sm:text-base md:text-lg text-gray-700 mb-8">
              <li>Innovative projects that challenge and inspire.</li>
              <li>A supportive and inclusive work culture.</li>
              <li>Opportunities for continuous learning and growth.</li>
              <li>Competitive compensation and benefits.</li>
            </ul>
          </div>

          {/* Image */}
          <div className="w-full lg:w-1/2 lg:pl-8">
            <img
              src="9-1.jpg"
              alt="Why Work at orbit25"
              className="w-full h-auto rounded-lg shadow-lg max-w-full"
            />
          </div>
        </motion.div>

        {/* As a Orbit25 Engineer Section */}
        <motion.div
          className="mt-12 sm:mt-24"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <div className="flex flex-col lg:flex-row items-start lg:items-center lg:justify-between">
            {/* Title */}
            <div className="w-full lg:w-1/2 lg:pr-8 mb-8 lg:mb-0">
              <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800 mb-4 sm:mb-6">
                As a Orbit25 Engineer
              </h2>
              <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4 sm:mb-6">
                As a software engineer at Orbit25, you will work with a talented
                team on exciting projects that push the boundaries of
                technology. You will have the opportunity to grow your skills,
                contribute to meaningful work, and make a real impact.
              </p>
            </div>

            {/* Points */}
            <div className="w-full lg:w-1/2 lg:pl-8">
              <ul className="list-disc pl-5 space-y-4 text-sm sm:text-base md:text-lg text-gray-700">
                <li>
                  You’ll work with new technologies, while they’re still new
                </li>
                <li>You will be trained with interactive training sessions</li>
                <li>You’ll learn to think like an entrepreneur</li>
                <li>You can play ping pong & carom at work</li>
                <li>You’ll discover how to make work fun</li>
              </ul>
            </div>
          </div>
        </motion.div>

        {/* Available Jobs Section */}
        <motion.div
          className="mt-12 sm:mt-24 px-4"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800 mb-4 sm:mb-6 text-center">
            Available Jobs
          </h2>
          <div className="space-y-4">
            {/* Loop through jobs */}
            {jobs.map((job, index) => (
              <motion.div
                key={index}
                className="flex flex-col sm:flex-row justify-between items-start bg-white p-4 rounded-lg shadow-lg space-y-4 sm:space-y-0 sm:space-x-4"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: index * 0.2 }}
              >
                {/* Left Section: Job Title & Description */}
                <div className="flex-1">
                  <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-800 mb-2">
                    {job.title}
                  </h3>
                  <div className="text-sm sm:text-base text-gray-700">
                    <p>
                      <strong>Location:</strong> {job.location}
                    </p>
                    <p>
                      <strong>Type:</strong> {job.type}
                    </p>
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-800 mb-2">
                    Open for apply
                  </h4>
                  <p className="text-sm sm:text-base text-gray-700">
                    No of vacancies: {job.vacancies}
                  </p>
                </div>
                {/* Right Section: Apply Now Button */}
                <div className="w-full sm:w-auto">
                    <button
                    className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg text-center block"
                    onClick={() => handleApplyClick(job)}
                  >
                    Apply Now
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Career;
