import React from "react";

const Team = () => {
  return (
    <main className="p-6 space-y-8 bg-gray-100">
      <section className="text-center mb-8">
        <h1 className="text-4xl font-bold mb-4 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-500 to-pink-600 animate-text pb-2">Our Expert Team</h1>
        <p className="text-gray-600 mt-2">
        With a 99% recommendation rate, Orbit 25 comprises a team of deeply passionate professionals from Bangladesh who align your business objectives with cutting-edge tech solutions. Known for our high-quality delivery processes, our team of web developers, designers, product managers, and data scientists have the expertise, creativity, and vision to craft beautiful and smart web applications and software products.
        <br/>
        </p>
      </section>

      <section
        id="management-team"
        className="bg-white p-6 rounded-lg shadow-md"
      >
        <h2 className="text-3xl font-bold mb-4 text-center text-gray-800">
          {/* Management Team */}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="p-4 bg-gray-50 rounded-lg shadow-md border-2">
            <div className="w-full h-auto">
              <img
                src="/alamin.jpg"
                alt="Management Team Member 1"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-xl font-extrabold text-center mb-2 text-transparent bg-clip-text bg-gradient-to-l from-green-400 via-teal-500 to-cyan-500 animate-text">
              Md Al Amin Chowdhury
            </h3>
            <p className="text-gray-600 text-center">Role: Software Engineer</p>
            <p className="text-gray-600 text-center">
            Experience: 2 Year
            </p>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg shadow-md border-2">
            <div className="w-full h-auto">
              <img
                src="/nuhan.jpg"
                alt="Management Team Member 2"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-xl font-extrabold text-center mb-2 text-transparent bg-clip-text bg-gradient-to-l from-green-400 via-teal-500 to-cyan-500 animate-text">
              Nuhan Ahmed Digonto
            </h3>
            <p className="text-gray-600 text-center">Role: Software Engineer</p>
            <p className="text-gray-600 text-center">
            Experience: 2 Year
            </p>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg shadow-md border-2">
            <div className="w-full h-auto">
              <img
                src="/s.jpg"
                alt="Management Team Member 3"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-xl font-extrabold text-center mb-2 text-transparent bg-clip-text bg-gradient-to-l from-green-400 via-teal-500 to-cyan-500 animate-text">
              Saiful Islam
            </h3>
            <p className="text-gray-600 text-center">Role: Software Engineer</p>
            <p className="text-gray-600 text-center">
            Experience: 1 Year
            </p>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg shadow-md border-2">
            <div className="w-full h-auto">
              <img
                src="/a.JPG"
                alt="Management Team Member 4"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-xl font-extrabold text-center mb-2 text-transparent bg-clip-text bg-gradient-to-l from-green-400 via-teal-500 to-cyan-500 animate-text">
              Anonnya Sarkar
            </h3>
            <p className="text-gray-600 text-center">Role: IT Executive</p>
            <p className="text-gray-600 text-center">
            Experience: 1 Year
            </p>
          </div>

          <div className="p-4 bg-gray-50 rounded-lg shadow-md border-2">
            <div className="w-full h-auto">
              <img
                src="/imam.jpeg"
                alt="Management Team Member 4"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-xl font-extrabold text-center mb-2 text-transparent bg-clip-text bg-gradient-to-l from-green-400 via-teal-500 to-cyan-500 animate-text0">
              Imam Hasan Nirob
            </h3>
            <p className="text-gray-600 text-center">Role: Graphic Designer</p>
            <p className="text-gray-600 text-center">
              Experience: 1 Year
            </p>
          </div>

          <div className="p-4 bg-gray-50 rounded-lg shadow-md border-2">
            <div className="w-full h-auto">
              <img
                src="/juin.jpg"
                alt="Management Team Member 4"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-xl font-extrabold text-center mb-2 text-transparent bg-clip-text bg-gradient-to-l from-green-400 via-teal-500 to-cyan-500 animate-text">
              Ifte Khar Uddin
            </h3>
            <p className="text-gray-600 text-center">Role: Marketing Manager</p>
            <p className="text-gray-600 text-center">
              Experience: 2 Year
            </p>
          </div>
        </div>
        
      </section>

      {/* <section
        id="engineering-team"
        className="bg-white p-6 rounded-lg shadow-md"
      >
        <h2 className="text-3xl font-bold mb-4 text-center text-gray-800">
          Engineering Team
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="p-4 bg-gray-50 rounded-lg shadow-md">
            <div className="w-full h-40">
              <img
                src="/alamin.jpg"
                alt="Engineering Team Member 1"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-700">
              Engineering Team Member 1
            </h3>
            <p className="text-gray-600">Role: Position</p>
            <p className="text-gray-600">
              Short bio or description of Engineering Team Member 1.
            </p>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg shadow-md">
            <div className="w-full h-40">
              <img
                src="https://via.placeholder.com/150"
                alt="Engineering Team Member 2"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-700">
              Engineering Team Member 2
            </h3>
            <p className="text-gray-600">Role: Position</p>
            <p className="text-gray-600">
              Short bio or description of Engineering Team Member 2.
            </p>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg shadow-md">
            <div className="w-full h-40">
              <img
                src="https://via.placeholder.com/150"
                alt="Engineering Team Member 3"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-700">
              Engineering Team Member 3
            </h3>
            <p className="text-gray-600">Role: Position</p>
            <p className="text-gray-600">
              Short bio or description of Engineering Team Member 3.
            </p>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg shadow-md">
            <div className="w-full h-40">
              <img
                src="https://via.placeholder.com/150"
                alt="Engineering Team Member 4"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-700">
              Engineering Team Member 4
            </h3>
            <p className="text-gray-600">Role: Position</p>
            <p className="text-gray-600">
              Short bio or description of Engineering Team Member 4.
            </p>
          </div>
        </div>
      </section>

      <section
        id="business-management-team"
        className="bg-white p-6 rounded-lg shadow-md"
      >
        <h2 className="text-3xl font-bold mb-4 text-center text-gray-800">
          Business Management Team
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="p-4 bg-gray-50 rounded-lg shadow-md">
            <div className="w-full h-40">
              <img
                src="/alamin.jpg"
                alt="Business Management Team Member 1"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-700">
              Business Management Team Member 1
            </h3>
            <p className="text-gray-600">Role: Position</p>
            <p className="text-gray-600">
              Short bio or description of Business Management Team Member 1.
            </p>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg shadow-md">
            <div className="w-full h-40">
              <img
                src="https://via.placeholder.com/150"
                alt="Business Management Team Member 2"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-700">
              Business Management Team Member 2
            </h3>
            <p className="text-gray-600">Role: Position</p>
            <p className="text-gray-600">
              Short bio or description of Business Management Team Member 2.
            </p>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg shadow-md">
            <div className="w-full h-40">
              <img
                src="https://via.placeholder.com/150"
                alt="Business Management Team Member 3"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-700">
              Business Management Team Member 3
            </h3>
            <p className="text-gray-600">Role: Position</p>
            <p className="text-gray-600">
              Short bio or description of Business Management Team Member 3.
            </p>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg shadow-md">
            <div className="w-full h-40">
              <img
                src="https://via.placeholder.com/150"
                alt="Business Management Team Member 4"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-700">
              Business Management Team Member 4
            </h3>
            <p className="text-gray-600">Role: Position</p>
            <p className="text-gray-600">
              Short bio or description of Business Management Team Member 4.
            </p>
          </div>
        </div>
      </section> */}
      {/* <section className="text-center mb-8">
        <p className="text-gray-600 mt-2">
        At Orbit 25, we pride ourselves on our collaborative approach, ensuring that every project we undertake is tailored to meet the unique needs of our clients. Our commitment to innovation and excellence drives us to stay at the forefront of technology, adopting the latest tools and methodologies to deliver cutting-edge solutions. Whether you need a robust web platform, an engaging mobile app, or sophisticated data analytics, our team has the skills and experience to turn your vision into reality.
        <br/><br/>
        Join the many satisfied clients who have experienced the Orbit 25 difference. Let us help you transform your ideas into high-performing digital products that stand out in today's competitive market.
        </p>
      </section> */}
    </main>
  );
};

export default Team;
