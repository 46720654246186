import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const SoftwareDevelopment = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.3,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const imageVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const listItemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <div className="w-full overflow-x-hidden">
      <div className="container mx-auto py-16 px-6 bg-gradient-to-b from-white to-gray-100">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-5xl font-extrabold mb-10 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-500 to-pink-600 animate-text pb-2"
        >
          Software Development
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
          className="text-xl text-gray-800 mb-12 text-center max-w-3xl mx-auto leading-relaxed"
        >
          Our software development services offer innovative solutions tailored
          to meet your business needs. We specialize in developing robust and
          scalable applications that drive success.
        </motion.p>

        {/* Services Section */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 0.2,
              },
            },
          }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        >
          {["Custom Software", "Mobile Applications", "Web Development"].map(
            (service, index) => (
              <motion.div
                key={index}
                variants={{
                  hidden: { opacity: 0, scale: 0.8 },
                  visible: { opacity: 1, scale: 1 },
                }}
                className={`p-8 rounded-xl shadow-2xl transform hover:scale-105 transition-all duration-500 ease-in-out ${
                  index === 0
                    ? "bg-gradient-to-r from-purple-400 via-pink-500 to-red-500"
                    : index === 1
                    ? "bg-gradient-to-r from-green-400 via-teal-500 to-cyan-500"
                    : "bg-gradient-to-r from-yellow-400 via-orange-500 to-red-500"
                }`}
              >
                <h2 className="text-2xl font-semibold mb-4 text-white">
                  {service}
                </h2>
                <p className="text-gray-200">
                  {service === "Custom Software"
                    ? "We build custom software solutions designed to meet your specific business requirements."
                    : service === "Mobile Applications"
                    ? "Our team develops mobile applications that provide a seamless user experience across all devices."
                    : "We create responsive and dynamic websites that help you reach a wider audience."}
                </p>
              </motion.div>
            )
          )}
        </motion.div>

        {/* Technologies Section */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.3 }}
          className="mt-20"
        >
          <h2 className="text-4xl font-bold mb-10 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-500 to-pink-600 animate-text pb-2">
            Technologies We Use
          </h2>
          <div className="flex justify-center flex-wrap gap-12">
            {["flutter.png", "java.png", "vues.png", "React1.png"].map((src, index) => (
              <motion.img
                key={index}
                src={src}
                alt={`Tech ${index + 1}`}
                className="h-20 transform hover:scale-110 transition-transform duration-300"
                whileHover={{ scale: 1.2 }}
              />
            ))}
          </div>
        </motion.div>

        {/* Process Section */}
        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          className="mt-20 flex flex-col lg:flex-row bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 p-12 rounded-xl shadow-2xl transform hover:scale-105 transition-all duration-500 ease-in-out"
        >
          <div className="lg:w-1/2 lg:pr-10 mb-10 lg:mb-0">
            <motion.h2
              initial="hidden"
              animate={controls}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.8, delay: 0.2 },
                },
              }}
              className="text-4xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-500 to-pink-600 animate-text"
            >
              Our Process
            </motion.h2>
            <motion.ul
              initial="hidden"
              animate={controls}
              variants={{
                hidden: { opacity: 0 },
                visible: {
                  opacity: 1,
                  transition: {
                    staggerChildren: 0.2,
                  },
                },
              }}
              className="list-disc list-inside text-gray-800 space-y-6 text-lg"
            >
              {[
                "Planning: We start by understanding your business requirements and creating a detailed plan.",
                "Analysis: Define and document the software's needs.",
                "Design: Our designers create user-friendly interfaces that align with your brand identity.",
                "Implementation: Develop and code the software.",
                "Testing & Integration: Verify the software's functionality and fix defects.",
                "Deployment: Release the software to the users.",
                "Maintenance: Provide updates and support post-deployment.",

              ].map((text, index) => (
                <motion.li key={index} variants={listItemVariants}>
                  <strong>{text.split(":")[0]}:</strong> {text.split(":")[1]}
                </motion.li>
              ))}
            </motion.ul>
          </div>
          <motion.div
            initial="hidden"
            animate={controls}
            variants={imageVariants}
            className="lg:w-1/2 lg:pl-10 mb-10 lg:mb-0"
          >
            <motion.img
              src="ad.png"
              alt="Process"
              className="rounded-lg w-full transform hover:scale-110 transition-transform duration-300"
            />
          </motion.div>
        </motion.div>

        {/* New Design Section */}
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="mt-20 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 p-12 rounded-xl shadow-2xl transform hover:scale-105 transition-all duration-500 ease-in-out"
        >
          <h2 className="text-4xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-500 to-pink-600 animate-text pb-2">
            Why Custom Software?
          </h2>
          <div className="flex flex-col lg:flex-row items-center">
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8 }}
              className="lg:w-1/2 lg:pr-10 mb-10 lg:mb-0"
            >
              <img
                src="14224.jpg"
                alt="Custom Software"
                className="rounded-lg shadow-lg w-full transform hover:scale-110 transition-transform duration-300"
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="lg:w-1/2"
            >
              <p className="text-lg text-gray-800 mb-6">
                Custom software development allows your business to tailor its
                tools to specific needs, providing flexibility, efficiency, and
                a competitive edge.
              </p>
              <ul
                className="list-disc list-inside text
-gray-800 mb-6 space-y-4"
              >
                <li>Optimized for your unique business processes</li>
                <li>Scalable and adaptable to future changes</li>
                <li>Better integration with existing systems</li>
              </ul>
            </motion.div>
          </div>
        </motion.div>

        {/* Testimonials Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.3 }}
          className="mt-20 bg-gray-200 py-16 px-6 rounded-xl shadow-lg"
        >
          <h2 className="text-4xl font-bold mb-10 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-500 to-pink-600 animate-text pb-2">
            What Our Clients Say
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {["Client A", "Client B", "Client C"].map((client, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.3 + index * 0.1 }}
                className="bg-white p-8 rounded-xl shadow-md transform hover:scale-105 transition-transform duration-500"
              >
                <p className="text-gray-700 mb-6">
                  "{client} has provided us with top-notch custom software that
                  has truly transformed our business operations."
                </p>
                <h3 className="text-xl font-semibold text-gray-800">
                  - {client}
                </h3>
              </motion.div>
            ))}
          </div>
        </motion.div>
        {/* Why Choose Us Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
          className="mt-20"
        >
          <h2 className="text-4xl font-bold mb-10 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-500 to-pink-600 animate-text pb-2">
            Why Choose Us?
          </h2>
          <motion.ul
            initial="hidden"
            whileInView="visible"
            transition={{ staggerChildren: 0.2, delayChildren: 0.5 }}
            className="list-none space-y-6 max-w-3xl mx-auto text-lg"
          >
            {[
              "Experienced and skilled developers",
              "Client-focused approach",
              "Timely delivery and support",
              "Cost-effective solutions",
              "Cutting-edge technologies",
            ].map((text, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                className="flex items-center bg-gradient-to-r from-blue-400 to-pink-500 text-white p-4 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
              >
                <span className="mr-4 p-2 bg-white rounded-full text-blue-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
                {text}
              </motion.li>
            ))}
          </motion.ul>
        </motion.div>

        {/* Call to Action Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
          className="mt-20 bg-gradient-to-r from-green-400 via-teal-500 to-cyan-500 p-12 rounded-xl shadow-2xl text-center"
        >
          <h2 className="text-3xl font-bold text-white mb-4">
            Ready to Elevate Your Business?
          </h2>
          <p className="text-lg text-white mb-6">
            Contact us today to discuss your software development needs and take
            the first step towards innovative solutions.
          </p>

          <a
            href="mailto:contact@orbit25.com"
            className="bg-gradient-to-r from-yellow-400 via-orange-500 to-red-500 text-white px-6 py-3 rounded-lg font-semibold hover:bg-gray-200 transition-all duration-300"
          >
            Get in Touch
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default SoftwareDevelopment;
