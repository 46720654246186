import React from "react";
import { motion } from "framer-motion";

const WebsiteDevelopment = () => {
  return (
    <div className="container mx-auto py-20 px-6 bg-gradient-to-b from-gray-100 to-white overflow-x-hidden">
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: -50, scale: 0.9 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        transition={{ duration: 1 }}
        className="text-center mb-20"
      >
        <h1 className="text-3xl md:text-5xl lg:text-5xl font-extrabold mb-6 md:mb-8 text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 via-purple-500 to-pink-500 leading-tight pb-2">
          Website Development
        </h1>
        <p className="text-lg md:text-xl lg:text-2xl text-gray-700 max-w-2xl md:max-w-3xl mx-auto leading-relaxed">
          Transform your business with our cutting-edge website development
          services. We create stunning, responsive websites that drive results
          and enhance your online presence.
        </p>
      </motion.div>

      {/* Services Section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: false, margin: "-100px 0px" }}
        transition={{ duration: 0.8 }}
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-12 mb-20"
      >
        {[
          {
            title: "Custom Websites",
            description:
              "Tailored solutions to meet your specific business needs and objectives.",
            gradient: "from-purple-600 via-pink-500 to-red-500",
          },
          {
            title: "E-commerce Solutions",
            description:
              "Comprehensive e-commerce platforms for seamless online sales.",
            gradient: "from-teal-400 via-green-400 to-lime-400",
          },
          {
            title: "Responsive Design",
            description:
              "Ensuring your website looks great and functions perfectly on any device.",
            gradient: "from-yellow-400 via-orange-500 to-red-500",
          },
          {
            title: "SEO Optimization",
            description:
              "Boost your website's visibility with our expert SEO services.",
            gradient: "from-indigo-500 via-blue-500 to-purple-500",
          },
          {
            title: "Content Management",
            description:
              "Easy-to-use CMS for effortless content updates and management.",
            gradient: "from-pink-400 via-purple-400 to-indigo-400",
          },
          {
            title: "Website Maintenance",
            description:
              "Reliable maintenance services to keep your site up-to-date and secure.",
            gradient: "from-blue-500 via-teal-500 to-green-500",
          },
        ].map((service, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false, margin: "-100px 0px" }}
            transition={{ duration: 0.8, delay: index * 0.1 }}
            whileHover={{ scale: 1.05, rotate: 2 }}
            className={`p-6 md:p-8 lg:p-10 rounded-xl shadow-lg bg-gradient-to-r ${service.gradient} text-white transform hover:-translate-y-3 hover:shadow-2xl transition-all duration-300 ease-out`}
          >
            <h2 className="text-2xl md:text-3xl lg:text-3xl font-bold mb-4">
              {service.title}
            </h2>
            <p className="text-base md:text-lg">{service.description}</p>
          </motion.div>
        ))}
      </motion.div>

      {/* Technologies Section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: false, margin: "-100px 0px" }}
        transition={{ duration: 0.8 }}
        className="mb-20"
      >
<h2 className="text-4xl md:text-5xl lg:text-5xl font-bold mb-10 text-center text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 via-purple-500 to-pink-500 leading-tight pb-2">
  Technologies We Use
</h2>

        <div className="flex flex-wrap justify-center space-x-6 md:space-x-12">
          {["HTML5", "CSS3", "JavaScript", "React", "Tailwind CSS"].map(
            (tech, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: false, margin: "-100px 0px" }}
                transition={{ duration: 0.8, delay: index * 0.1 }}
                whileHover={{ scale: 1.1, rotate: 10 }}
                animate={{
                  rotate: [0, 360],
                  transition: {
                    repeat: Infinity,
                    duration: 10,
                    ease: "linear",
                  },
                }}
                className="w-20 h-20 flex flex-col items-center justify-center transform hover:scale-110 transition-all duration-300"
              >
                <img
                  src={`${tech.toLowerCase()}.png`}
                  alt={tech}
                  className="w-16 h-16 md:w-20 md:h-20 object-contain filter drop-shadow-md"
                />
                <p className="text-center text-lg md:text-xl mt-4 font-semibold text-gray-800">
                  {tech}
                </p>
              </motion.div>
            )
          )}
        </div>
      </motion.div>

      {/* Portfolio Section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: false, margin: "-100px 0px" }}
        transition={{ duration: 0.8 }}
        className="mb-20"
      >
        <h2 className="text-4xl md:text-5xl lg:text-5xl font-bold mb-10 text-center text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 via-purple-500 to-pink-500">
          Our Portfolio
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-12">
          {[
            {
              title: "E-Commerce Platform",
              description:
                "A full-featured e-commerce platform with seamless payment integration and user-friendly design.",
              image: "/Capture11.PNG",
            },
            {
              title: "Social Networking Web",
              description:
                "A vibrant social networking web that connects people through shared interests and activities.",
              image: "/so.jpg",
            },
            {
              title: "Travel Booking System",
              description:
                "An intuitive travel booking system offering flights, hotels, and rental cars with real-time availability.",
              image: "/Screenshot 2024-08-16 123122.png",
            },
          ].map((project, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false, margin: "-100px 0px" }}
              transition={{ duration: 0.8, delay: index * 0.1 }}
              whileHover={{ scale: 1.05 }}
              className="bg-white p-6 md:p-8 lg:p-10 rounded-xl shadow-lg transform hover:-translate-y-3 hover:shadow-2xl transition-all duration-300 ease-out"
            >
              <img
                src={project.image}
                alt={project.title}
                className="mb-4 md:mb-6 rounded-lg w-full h-64 object-cover"
              />
              <h3 className="text-2xl md:text-3xl lg:text-3xl font-semibold mb-3 text-indigo-800">
                {project.title}
              </h3>
              <p className="text-base md:text-lg">{project.description}</p>
            </motion.div>
          ))}
        </div>
      </motion.div>

      {/* Testimonials Section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: false, margin: "-100px 0px" }}
        transition={{ duration: 0.8 }}
        className="mb-20"
      >
<h2 className="text-4xl md:text-5xl lg:text-5xl font-bold mb-10 text-center text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 via-purple-500 to-pink-500 leading-tight pb-2">
  What Our Clients Say
</h2>

        <div className="flex flex-wrap justify-center gap-8 md:gap-12">
          {[
            {
              name: "Jane Doe",
              feedback:
                "Their expertise in website development is unparalleled. Highly recommend!",
              photo: "path-to-jane-smith.jpg",
            },
            {
              name: "Sam Wilson",
              feedback:
                "They transformed our online presence with a stunning and responsive website.",
              photo: "path-to-sam-wilson.jpg",
            },
          ].map((testimonial, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false, margin: "-100px 0px" }}
              transition={{ duration: 0.8, delay: index * 0.1 }}
              whileHover={{ scale: 1.05 }}
              className="bg-white p-10 rounded-xl shadow-lg transform hover:-translate-y-3 hover:shadow-2xl transition-all duration-300 ease-out"
            >
              <img
                src={testimonial.photo}
                alt={testimonial.name}
                className="mb-6 w-24 h-24 rounded-full mx-auto"
              />
              <h4 className="text-2xl font-bold mb-4 text-center">
                {testimonial.name}
              </h4>
              <p className="text-gray-700 text-center">
                {testimonial.feedback}
              </p>
            </motion.div>
          ))}
        </div>
      </motion.div>

      {/* Why Choose Us Section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: false, margin: "-100px 0px" }}
        transition={{ duration: 0.8 }}
        className="mb-20"
      >
        <h2 className="text-4xl md:text-5xl lg:text-5xl font-bold mb-10 text-center text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 via-purple-500 to-pink-500 pb-2">
          Why Choose Us?
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <ul className="space-y-6">
            {[
              "Expert team of developers and designers.",
              "Tailored solutions for your specific needs.",
              "Commitment to delivering on time and within budget.",
              "Focus on user experience and customer satisfaction.",
              "Use of the latest technologies and best practices.",
            ].map((reason, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: false, margin: "-100px 0px" }}
                transition={{ duration: 0.8, delay: index * 0.1 }}
                whileHover={{ scale: 1.05, color: "#6366F1" }}
                className="p-4 bg-gradient-to-r from-gray-100 to-gray-200 rounded-lg shadow-lg transform hover:-translate-y-2 hover:shadow-2xl transition-all duration-300 ease-out"
              >
                {reason}
              </motion.li>
            ))}
          </ul>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: false, margin: "-100px 0px" }}
            transition={{ duration: 0.8 }}
            className="flex justify-center"
          >
            <img
              src="customsoftware.jpg"
              alt="Why Choose Us"
              className="rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300 ease-out"
            />
          </motion.div>
        </div>
      </motion.div>

      {/* Process Section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: false, margin: "-100px 0px" }}
        transition={{ duration: 0.8 }}
        className="mb-20"
      >
        <h2 className="text-4xl md:text-5xl lg:text-5xl font-bold mb-10 text-center text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 via-purple-500 to-pink-500 pb-2">
          Our Process
        </h2>
        <div className="flex flex-col lg:flex-row lg:space-x-12">
          {[
            {
              step: 1,
              title: "Discovery",
              description:
                "We start by understanding your business goals and target audience.",
            },
            {
              step: 2,
              title: "Design",
              description:
                "Our team creates wireframes and design prototypes for your approval.",
            },
            {
              step: 3,
              title: "Development",
              description:
                "We build and test your website, ensuring it meets all requirements.",
            },
            {
              step: 4,
              title: "Launch",
              description:
                "After rigorous testing, we launch your website and monitor its performance.",
            },
            {
              step: 5,
              title: "Support",
              description:
                "We provide ongoing support and maintenance to keep your website up-to-date.",
            },
          ].map((process, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false, margin: "-100px 0px" }}
              transition={{ duration: 0.8, delay: index * 0.1 }}
              className="flex-1 bg-white p-6 md:p-8 lg:p-10 rounded-xl shadow-lg mb-8 lg:mb-0"
            >
              <h3 className="text-xl md:text-3xl lg:text-4xl font-bold mb-4 text-indigo-800">
                Step {process.step}
              </h3>
              <h4 className="text-2xl md:text-2xl lg:text-2xl font-semibold mb-2">
                {process.title}
              </h4>
              <p className="text-base md:text-lg text-gray-700">
                {process.description}
              </p>
            </motion.div>
          ))}
        </div>
      </motion.div>

      {/* Contact Section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: false, margin: "-100px 0px" }}
        transition={{ duration: 0.8 }}
        className="text-center"
      >
        <h2 className="text-4xl md:text-5xl lg:text-5xl font-bold mb-10 text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 via-purple-500 to-pink-500 pb-2">
          Get In Touch
        </h2>
        <p className="text-lg md:text-xl lg:text-2xl text-gray-700 mb-8">
          Ready to start your project or need more information? Contact us
          today!
        </p>
        <a
          href="mailto:contact@orbit25.com"
          className="inline-block px-8 py-4 text-xl font-semibold text-white bg-indigo-600 rounded-full shadow-lg transform hover:scale-105 transition-all duration-300"
        >
          Contact Us
        </a>
      </motion.div>
    </div>
  );
};

export default WebsiteDevelopment;
