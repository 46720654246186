import React from "react";
import { motion } from "framer-motion";
import { FaCheckCircle } from "react-icons/fa";

const CloudCyberSecurity = () => {
  return (
    <div className="container mx-auto py-12 px-4 space-y-12">
      <motion.div
        className="bg-cover bg-blue-800 bg-center text-white py-24 px-4 rounded-lg relative"
        style={{
          backgroundImage: "url('/pngegg(8).png')", // Replace with your image path
        }}
        role="banner"
        aria-label="Cloud and Cyber Security"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className="text-4xl font-bold mb-6 text-center"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.8 }}
        >
          Empowering Your Business with IT, Cloud, <br />& Cybersecurity
          Solutions and Support
        </motion.h1>
        <motion.p
          className="text-lg mb-8 text-center max-w-3xl mx-auto leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6, duration: 1 }}
        >
          The game is changing. Business is no longer the same. Compliance,
          ransomware, audits, employee offboarding, and technology forecasting
          are just a few of the challenges business owners and decision-makers
          encounter in their ever-evolving work environments. Should I move my
          internal IT staff to an outsourced solution, or might I consider a
          co-managed approach instead? Is Cyber Support really necessary for my
          business, or is IT Support strong enough on its own? We understand
          these concerns, and we're here to help you navigate through them.
        </motion.p>
        <div className="text-center">
          <motion.a
            href="/contact"
            className="inline-block px-8 py-4 text-xl font-semibold text-white bg-gradient-to-r from-teal-400 via-green-400 to-lime-400 rounded-full shadow-lg transform hover:scale-105 transition-all duration-300"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            Schedule a Free Consultation
          </motion.a>
        </div>
      </motion.div>

      {/* Service Cards Section */}
      <div className="relative">
        <motion.div
          className="relative z-10 grid grid-cols-1 lg:grid-cols-5 gap-8 px-4 -mt-24"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: { delay: 0.5, staggerChildren: 0.2 },
            },
          }}
        >
          {[
            {
              id: "cloud-security",
              imgSrc: "/cloud-ssecurity.png",
              title: "Cloud Security",
              description:
                "Our cloud security solutions safeguard your data and applications in the cloud environment.",
            },
            {
              id: "it-support",
              imgSrc: "/tech-support-2.png",
              title: "IT Support",
              description:
                "Our IT support services ensure your systems are always running smoothly.",
            },
            {
              id: "cyber-support",
              imgSrc: "/cy.png",
              title: "Cyber Support",
              description:
                "We provide robust network security measures to protect your IT infrastructure from threats.",
            },
            {
              id: "advanced-support",
              imgSrc: "/add.png",
              title: "Advanced Support",
              description:
                "We provide advanced technical support tailored to your business needs.",
            },
            {
              id: "data-protection",
              imgSrc: "/dt.png",
              title: "Data Protection",
              description:
                "Ensure the integrity and confidentiality of your data with our advanced data protection services.",
            },
          ].map((card) => (
            <motion.div
              key={card.id}
              className="bg-white p-6 rounded-lg shadow-lg transition-shadow duration-300 text-gray-900 flex flex-col items-center" // Flexbox center alignment
              role="article"
              aria-labelledby={card.id}
              whileHover={{
                scale: 1.05,
                backgroundColor: "#E0F2FE", // Light blue hover effect
              }}
            >
              <img
                src={card.imgSrc}
                alt={`${card.title} Logo`}
                className="h-16 mb-4"
              />
              <h2
                id={card.id}
                className="text-2xl font-semibold mb-4 text-indigo-800 text-center"
              >
                {card.title}
              </h2>
              <p className="text-gray-600 text-center">{card.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>

    {/* Cloud Security Section */}
<section id="cloud-security" className="py-16 bg-gradient-to-r from-blue-200 via-blue-100 to-blue-300">
  <div className="container mx-auto px-4">
    <motion.h2
      className="text-4xl font-bold text-center text-indigo-800 mb-8"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      Cloud Security & Support
    </motion.h2>
    <div className="flex flex-col lg:flex-row items-center justify-between gap-8">
      {/* Left Side: Cloud Security Text */}
      <motion.div
        className="lg:w-1/2"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6 }}
      >
        <p className="text-gray-700 mb-6 text-xl">
          Our cloud security solutions safeguard your data and
          applications in the cloud environment, providing comprehensive
          protection against a wide range of threats. With our advanced
          security measures, you can ensure that your data is encrypted,
          access is controlled, threats are detected, and compliance is
          managed effectively.
        </p>
        <ul className="list-disc list-inside text-gray-700 space-y-2 text-xl font-semibold">
          <li>Data Encryption</li>
          <li>Access Control</li>
          <li>Threat Detection</li>
          <li>Compliance Management</li>
        </ul>
      </motion.div>

      {/* Right Side: Logos with Names */}
      <motion.div
        className="lg:w-1/2 grid grid-cols-2 md:grid-cols-4 gap-8"
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6 }}
      >
        <div className="flex flex-col items-center">
          <img src="/SSL.png" alt="Logo 1" className="w-20 h-20 mb-4" />
          <p className="text-gray-700 text-center">CloudGuard</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="/SSN.png" alt="Logo 2" className="w-20 h-20 mb-4" />
          <p className="text-gray-700 text-center">SecureNet</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="/data-shield.png" alt="Logo 3" className="w-20 h-20 mb-4" />
          <p className="text-gray-700 text-center">DataShield</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="/accesspro.png" alt="Logo 4" className="w-20 h-20 mb-4" />
          <p className="text-gray-700 text-center">AccessPro</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="/threat.png" alt="Logo 5" className="w-20 h-20 mb-4" />
          <p className="text-gray-700 text-center">ThreatWatch</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="/safe.png" alt="Logo 6" className="w-20 h-20 mb-4" />
          <p className="text-gray-700 text-center">ComplianceSafe</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="/EncryptEase.png" alt="Logo 7" className="w-20 h-20 mb-4" />
          <p className="text-gray-700 text-center">EncryptEase</p>
        </div>

        <div className="flex flex-col items-center">
          <img src="/CloudLock.png" alt="Logo 8" className="w-20 h-20 mb-4" />
          <p className="text-gray-700 text-center">CloudLock</p>
        </div>
      </motion.div>
    </div>
  </div>
</section>


      {/* Cloud Security Section */}
      {/* <section id="cloud-security" className="py-16 bg-gradient-to-r from-slate-300 via-slate-100 to-slate-300">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-4xl font-bold text-center text-indigo-800 mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Cloud Security
          </motion.h2>
          <div className="flex flex-col lg:flex-row items-center justify-between gap-8">
            <motion.div
              className="lg:w-1/2"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <img
                src="/Cloud-Security-On-Workloads-3.png"
                alt="Cloud Security"
                className="w-full h-auto max-h-80 object-contain "
              />
            </motion.div>
            <motion.div
              className="lg:w-1/2"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <p className="text-gray-700 mb-6 text-xl">
                Our cloud security solutions safeguard your data and
                applications in the cloud environment, providing comprehensive
                protection against a wide range of threats.
              </p>
              <ul className="list-disc list-inside text-gray-700 text-xl font-semibold space-y-2">
                <li>Data Encryption</li>
                <li>Access Control</li>
                <li>Threat Detection</li>
                <li>Compliance Management</li>
              </ul>
            </motion.div>
          </div>
        </div>
      </section> */}

      {/* IT Support Section */}
      <section id="it-support" className="py-16 bg-gradient-to-r from-slate-300 via-slate-100 to-slate-300">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-4xl font-bold text-center text-indigo-800 mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            IT Support
          </motion.h2>
          <div className="flex flex-col lg:flex-row items-center justify-between gap-8">
            {/* Left Side: IT Support Text */}
            <motion.div
              className="lg:w-1/2"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <p className="text-gray-700 mb-6 text-xl">
                Our IT Support services are designed to help you manage and
                maintain your technology infrastructure, ensuring that your
                systems are always running smoothly and efficiently. We provide
                a range of services to support your IT needs, from
                troubleshooting and repairs to regular maintenance and
                monitoring.
              </p>
              <ul className="list-disc list-inside text-gray-700 text-xl font-semibold space-y-2">
                <li>24/7 Technical Support</li>
                <li>System Monitoring</li>
                <li>Hardware and Software Maintenance</li>
                <li>Remote Assistance</li>
              </ul>
            </motion.div>

            {/* Right Side: Logos with Names */}
            <motion.div
              className="lg:w-1/2 grid grid-cols-2 md:grid-cols-4 gap-8"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <div className="flex flex-col items-center">
                <img
                  src="/transfer_3797858.png"
                  alt="IT Support Logo 1"
                  className="w-20 h-20 mb-4"
                />
                <p className="text-gray-700 text-center">N-Central RMM</p>
              </div>

              <div className="flex flex-col items-center">
                <img
                  src="/advice_5121985.png"
                  alt="IT Support Logo 2"
                  className="w-20 h-20 mb-4"
                />
                <p className="text-gray-700 text-center">Unlimited Support</p>
              </div>

              <div className="flex flex-col items-center">
                <img
                  src="/headphone_6149556.png"
                  alt="IT Support Logo 3"
                  className="w-20 h-20 mb-4"
                />
                <p className="text-gray-700 text-center">Live Help Desk</p>
              </div>

              <div className="flex flex-col items-center">
                <img
                  src="/itsc.png"
                  alt="IT Support Logo 4"
                  className="w-20 h-20 mb-4"
                />
                <p className="text-gray-700 text-center">SentinelOne EDR</p>
              </div>

              <div className="flex flex-col items-center">
                <img
                  src="/online.png"
                  alt="IT Support Logo 5"
                  className="w-20 h-20 mb-4"
                />
                <p className="text-gray-700 text-center">Vendor Liaison</p>
              </div>

              <div className="flex flex-col items-center">
                <img
                  src="/clock.png"
                  alt="IT Support Logo 6"
                  className="w-20 h-20 mb-4"
                />
                <p className="text-gray-700 text-center">Rapid Response</p>
              </div>

              <div className="flex flex-col items-center">
                <img
                  src="/pngegg(11).png"
                  alt="IT Support Logo 7"
                  className="w-20 h-20 mb-4"
                />
                <p className="text-gray-700 text-center">Virtual CIO</p>
              </div>

              <div className="flex flex-col items-center">
                <img
                  src="/server.png"
                  alt="IT Support Logo 8"
                  className="w-20 h-20 mb-4"
                />
                <p className="text-gray-700 text-center">Endpoint Backup</p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* IT Support Section */}
      {/* <section id="it-support" className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-3xl font-semibold text-center text-purple-500 mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            IT Support
          </motion.h2>
          <div className="flex flex-col lg:flex-row items-center justify-between gap-8">
            <motion.div
              className="lg:w-1/2"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <img src="/itsp.png" alt="IT Support" className="w-full h-auto" />
            </motion.div>
            <motion.div
              className="lg:w-1/2"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <p className="text-gray-700 mb-6">
                Our IT support services ensure that your business systems are
                always running smoothly, providing both proactive and reactive
                support tailored to your needs.
              </p>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>24/7 Monitoring</li>
                <li>Remote and On-Site Support</li>
                <li>System Maintenance</li>
                <li>Software Updates</li>
              </ul>
            </motion.div>
          </div>
        </div>
      </section> */}

      {/* Cyber Support Section */}
      <section id="cyber-support" className="py-16 bg-gradient-to-r from-blue-200 via-blue-100 to-blue-300">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-4xl font-bold text-center text-indigo-800 mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Cyber Support
          </motion.h2>
          <div className="flex flex-col lg:flex-row items-center justify-between gap-8">
            <motion.div
              className="lg:w-1/2"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <img
                src="/cyb.png"
                alt="Cyber Support"
                className="w-full h-auto "
              />
            </motion.div>
            <motion.div
              className="lg:w-1/2"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <p className="text-gray-700 text-xl mb-6">
                Protect your IT infrastructure from cyber threats with our
                advanced cyber support services, including robust security
                measures and expert consultation.
              </p>
              <ul className="list-disc list-inside text-gray-700 text-xl font-semibold space-y-2">
                <li>Firewall Management</li>
                <li>Intrusion Detection</li>
                <li>Security Audits</li>
                <li>Incident Response</li>
              </ul>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Advanced Support Section */}
      <section id="advanced-support" className="py-16 bg-gradient-to-r from-slate-300 via-slate-100 to-slate-300">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-4xl font-bold text-center text-indigo-800 mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Advanced Support
          </motion.h2>
          <div className="flex flex-col lg:flex-row items-center justify-between gap-8">
            <motion.div
              className="lg:w-1/2"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <img
                src="/advanced-support-banner.png"
                alt="Advanced Support"
                className="w-full h-auto"
              />
            </motion.div>
            <motion.div
              className="lg:w-1/2"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <p className="text-gray-700 text-xl mb-6">
                Our advanced support services provide you with expert
                assistance, customized solutions, and ongoing support to keep
                your business ahead of the curve.
              </p>
              <ul className="list-disc list-inside text-gray-700 text-xl font-semibold space-y-2">
                <li>Technical Consultation</li>
                <li>Custom Software Development</li>
                <li>Integration Services</li>
                <li>Continuous Support</li>
              </ul>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Data Protection Section */}
      <section id="data-protection" className="py-16 bg-gradient-to-r from-blue-200 via-blue-100 to-blue-300">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-4xl font-bold text-center text-indigo-800 mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Data Protection
          </motion.h2>
          <div className="flex flex-col lg:flex-row items-center justify-between gap-8">
            <motion.div
              className="lg:w-1/2"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <img
                src="/dpb.png"
                alt="Data Protection"
                className="w-full h-auto"
              />
            </motion.div>
            <motion.div
              className="lg:w-1/2"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <p className="text-gray-700 text-xl mb-6">
                Ensure the integrity and confidentiality of your data with our
                advanced data protection services, featuring cutting-edge
                encryption and backup solutions.
              </p>
              <ul className="list-disc list-inside text-gray-700 text-xl font-semibold space-y-2">
                <li>Data Encryption</li>
                <li>Backup and Recovery</li>
                <li>Data Loss Prevention</li>
                <li>Compliance Assurance</li>
              </ul>
            </motion.div>
          </div>
        </div>
      </section>



      {/* Security Tools Showcase */}
      <motion.div
        className="mt-12 bg-purple-50 py-12 rounded-lg"
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: {
            opacity: 1,
            y: 0,
            transition: { delay: 0.8, staggerChildren: 0.2 },
          },
        }}
      >
        <h2 className="text-4xl font-bold mb-6 text-center text-indigo-800">
          Security Tools We Use
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 justify-items-center">
          <motion.div
            className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            whileHover={{ scale: 1.1 }}
          >
            <img
              src="AdvancedFirewalls.png"
              alt="Advanced Firewalls"
              className="h-16 mx-auto mb-4"
            />
            <p className="text-gray-700 text-center">Advanced Firewalls</p>
          </motion.div>
          <motion.div
            className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            whileHover={{ scale: 1.1 }}
          >
            <img
              src="DataEncryption.png"
              alt="Data Encryption"
              className="h-16 mx-auto mb-4"
            />
            <p className="text-gray-700 text-center">Data Encryption</p>
          </motion.div>
          <motion.div
            className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            whileHover={{ scale: 1.1 }}
          >
            <img
              src="Monitoring.png"
              alt="24/7 Monitoring"
              className="h-16 mx-auto mb-4"
            />
            <p className="text-gray-700 text-center">24/7 Monitoring</p>
          </motion.div>
          <motion.div
            className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            whileHover={{ scale: 1.1 }}
          >
            <img
              src="Secure.png"
              alt="Secure VPNs"
              className="h-16 mx-auto mb-4"
            />
            <p className="text-gray-700 text-center">Secure VPNs</p>
          </motion.div>
        </div>
      </motion.div>

      {/* Testimonials Section */}
      <motion.div
        className="mt-12 bg-purple-100 py-12 rounded-lg"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.9, duration: 1 }}
      >
        <h2 className="text-4xl font-bold mb-6 text-center text-indigo-800">
          What Our Clients Say
        </h2>
        <div className="flex flex-col md:flex-row justify-around items-center space-y-8 md:space-y-0">
          <motion.div
            className="text-center max-w-sm mx-auto"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <p className="text-gray-600 text-xl italic">
              "The cloud security solutions provided by this team have given us
              peace of mind, knowing our data is safe."
            </p>
            <p className="text-indigo-800 font-bold mt-3">
              - Sarah Johnson, CTO of TechCorp
            </p>
          </motion.div>
          <motion.div
            className="text-center max-w-sm mx-auto"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <p className="text-gray-600 text-xl italic">
              "Their network security services are top-notch. We've never felt
              more secure."
            </p>
            <p className="text-indigo-800 font-bold mt-3">
              - Mark Davis, CEO of Innovate Inc.
            </p>
          </motion.div>
        </div>
      </motion.div>
      {/* FAQ Section */}
      <motion.div
        className="mt-24 space-y-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6, duration: 1 }}
      >
        <h2 className="text-4xl font-bold text-center text-indigo-800">
          Frequently Asked Questions
        </h2>
        <div className="space-y-4">
          <motion.details
            className="bg-white p-6 rounded-lg shadow-md"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <summary className="text-lg font-bold text-gray-800 cursor-pointer">
              What is cloud security?
            </summary>
            <p className="text-gray-600 mt-2">
              Cloud security refers to a set of policies, controls, procedures,
              and technologies that work together to protect cloud-based
              systems, data, and infrastructure.
            </p>
          </motion.details>
          <motion.details
            className="bg-white p-6 rounded-lg shadow-md"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <summary className="text-lg font-bold text-gray-800 cursor-pointer">
              Why is network security important?
            </summary>
            <p className="text-gray-600 mt-2">
              Network security is crucial for protecting your IT infrastructure
              from unauthorized access, data breaches, and other cybersecurity
              threats.
            </p>
          </motion.details>
          <motion.details
            className="bg-white p-6 rounded-lg shadow-md"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <summary className="text-lg font-bold text-gray-800 cursor-pointer">
              How can I ensure data protection?
            </summary>
            <p className="text-gray-600 mt-2">
              Data protection can be ensured through encryption, secure backup
              practices, and compliance with data protection regulations.
            </p>
          </motion.details>
        </div>
      </motion.div>
      {/* Call-to-Action Banner */}
      <motion.div
        className="mt-24 bg-gradient-to-r from-yellow-400 via-orange-500 to-red-500 text-white py-16 px-4 rounded-lg text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4, duration: 1 }}
      >
        <motion.h2
          className="text-3xl font-bold mb-4"
          initial={{ y: -30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Ready to Secure Your Business?
        </motion.h2>
        <motion.p
          className="text-lg mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 1 }}
        >
          Contact us today and take the first step towards a safer, more secure
          future.
        </motion.p>
        <motion.a
          href="/contact"
          className="inline-block px-10 py-4 text-xl font-semibold text-white bg-indigo-800 rounded-full shadow-lg transform hover:scale-105 transition-all duration-300"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          Get in Touch
        </motion.a>
      </motion.div>
    </div>
  );
};

export default CloudCyberSecurity;
