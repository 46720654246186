import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Define the Section component without a background color class
const Section = ({ title, children, textColor = "text-gray-800" }) => (
  <section className={`${textColor}`}>
    <h2 className="text-4xl font-bold mb-6 text-center">{title}</h2>
    {children}
  </section>
);


const techIcons = [
  { src: "react.png", alt: "React" },
  { src: "/nodejs.png", alt: "Node.js" },
  { src: "/ex.png", alt: "Express" },
  { src: "/mongodb.png", alt: "MongoDB" },
  { src: "/ts.png", alt: "TypeScript" },
  { src: "/vue.png", alt: "Vue.js" },
  { src: "/angular.png", alt: "Angular" },
  { src: "/python.png", alt: "Python" },
  { src: "/django.png", alt: "Django" },
  { src: "/php.png", alt: "Php" },
  { src: "/postgresql.png", alt: "postgresql" },
  { src: "/nest.png", alt: "Nest" },
  { src: "/next.png", alt: "Next" },
  { src: "/js.png", alt: "Js" },
  { src: "/laravel.png", alt: "Laravel" },
  { src: "/aws.png", alt: "AWS" },
  { src: "/gcp.png", alt: "GCP" },
  { src: "/azure.png", alt: "Azure" },
  { src: "/kubernetes.png", alt: "Kubernetes" },
  { src: "/docker.png", alt: "Docker" },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 8,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 100,
  arrows: false,
  centerMode: false,
  rows: 1,
};

const LatestTechSection = () => (
  <Section
    title="We are Working with this all of Latest Technology"
    textColor="text-gray-800"
  >
    <p className="text-lg text-center text-gray-700 max-w-4xl mx-auto mt-6">
      Architecting Excellence through Cutting-Edge Technology: Our Commitment to
      Harnessing a Diverse Spectrum of State-of-the-Art Innovations to Drive
      Transformation and Elevate Solutions.
    </p>
    <div className="overflow-hidden">
      <Slider {...settings}>
        {techIcons.map((tech, index) => (
          <div key={index} className="  h-24">
            <img src={tech.src} alt={tech.alt} className="max-h-full" />
          </div>
        ))}
      </Slider>
    </div>
  </Section>
);

export default LatestTechSection;
