import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ApplyJob = () => {
  const location = useLocation();
  const job = location.state || {};
  const {
    experienceRequirements = [],
    qualifications = [],
    responsibilities = [],
    positionOverview = ""
  } = job;

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-800 mb-8">
          {job.title || "Job Title"}
        </h1>

        <p className="text-lg text-gray-700 mb-12 text-center">
          {job.pagedescription}
        </p>

        {/* Job Details Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg mb-16">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Job Details
          </h2>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Description:</strong> {job.description || "Description"}
          </p>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Location:</strong> {job.location || "Location"}
          </p>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Type:</strong> {job.type || "Job Type"}
          </p>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Experience:</strong> {job.experience || "Experience Required"}
          </p>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Salary:</strong> {job.salary || "Salary Required"}
          </p>
        </div>

        {/* Company Overview Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg mb-16">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Company Overview
          </h2>
          <p className="text-lg text-gray-700 mb-6">
            {job.companyOverview || "Company overview details go here."}
          </p>
        </div>

        {/* Position Overview Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg mb-16">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Position Overview
          </h2>
          <p className="text-lg text-gray-700 mb-6">
            {positionOverview || "Position overview details go here."}
          </p>
        </div>

        {/* Experience Requirements Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg mb-16">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Experience Requirements
          </h2>
          <ul className="list-disc pl-6 text-lg text-gray-700 mb-12">
            {experienceRequirements.length > 0 ? (
              experienceRequirements.map((requirement, index) => (
                <li key={index}>{requirement}</li>
              ))
            ) : (
              <li>No specific experience requirements listed.</li>
            )}
          </ul>
        </div>

        {/* Responsibilities Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg mb-16">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Responsibilities
          </h2>
          <ul className="list-disc pl-6 text-gray-700">
            {responsibilities.length > 0 ? (
              responsibilities.map((responsibility, index) => (
                <li key={index} className="mb-2">
                  {responsibility}
                </li>
              ))
            ) : (
              <li>No specific responsibilities listed.</li>
            )}
          </ul>
        </div>

        {/* Qualifications Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg mb-16">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Qualifications
          </h2>
          <ul className="list-disc pl-6 text-gray-700">
            {qualifications.length > 0 ? (
              qualifications.map((qualification, index) => (
                <li key={index} className="mb-2">
                  {qualification}
                </li>
              ))
            ) : (
              <li>No specific qualifications listed.</li>
            )}
          </ul>
        </div>

        {/* Compensation & Other Benefits Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg mb-16">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Compensation & Other Benefits
          </h2>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Medical Allowance:</strong> Yes
          </p>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Performance Bonus:</strong> Yes
          </p>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Weekly Holidays:</strong> 2
          </p>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Insurance:</strong> Yes
          </p>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Lunch Facilities:</strong> Partially Subsidized
          </p>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Salary Review:</strong> Yearly
          </p>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Festival Bonus:</strong> 2 (Yearly)
          </p>
        </div>

        {/* More Info Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg mb-16">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            More Info
          </h2>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Employment Status:</strong> Full Time
          </p>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Workplace:</strong> Work at Office / Remote
          </p>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Job Location:</strong> Dhaka
          </p>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Salary:</strong> Negotiable
          </p>
        </div>

        {/* Apply Now Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg mb-16">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Apply Now
          </h2>
          <p className="text-lg text-gray-700 mb-6">
            <strong>How to Apply:</strong> To apply for this position, please send your CV to <a href="mailto:career@orbit-25.com" className="text-blue-500 underline">career@orbit-25.com</a> (HR) with the job title in the email subject line.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ApplyJob;
