import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen && isServicesOpen) {
      setIsServicesOpen(false); // Close the sub-menu when main menu closes
    }
  };

  const handleMouseEnter = () => {
    if (window.innerWidth >= 1024) {
      // Only open sub-menu on hover for larger screens
      setIsServicesOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth >= 1024) {
      // Only close sub-menu on hover for larger screens
      setIsServicesOpen(false);
    }
  };

  const handleServiceClick = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const closeSubMenu = () => {
    setIsServicesOpen(false);
  };

  return (
    <header className="bg-gradient-to-r from-gray-800 via-gray-900 to-black text-white p-4 shadow-lg relative z-50">
      <nav className="container mx-auto flex flex-wrap items-center justify-between">
        {/* Logo */}
        <div className="text-2xl sm:text-4xl font-bold">
          <Link to="/">
            <img
              src="logo.png"
              alt="Logo"
              className="h-12 sm:h-16 transition-transform transform hover:scale-110"
            />
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <div className="block lg:hidden">
          <button
            onClick={toggleMenu}
            className="flex items-center px-3 py-2 border rounded text-gray-400 border-gray-400 hover:text-white hover:border-white transition-colors"
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        {/* Navigation Links */}
        <div
          className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${
            isMenuOpen ? "" : "hidden"
          } lg:block`}
        >
          <ul className="flex flex-col lg:flex-row lg:space-x-10 mt-4 lg:mt-0 lg:ml-auto">
            <li>
              <Link
                to="/"
                className="block lg:inline-block hover:text-gray-400 p-2 lg:p-0 transition-colors"
                onClick={toggleMenu}
              >
                Home
              </Link>
            </li>
            <li
              className="relative group"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <button
                onClick={handleServiceClick}
                className="flex items-center text-white hover:text-gray-400 p-2 lg:p-0 transition-colors"
              >
                <span className="mr-2">Services</span>
                <FaChevronDown />
              </button>
              <ul
                className={`absolute left-0 mt-0 pt-2 w-60 bg-gray-800 text-white shadow-lg rounded-lg py-4 grid grid-cols-1 gap-2 ${
                  isServicesOpen ? "block" : "hidden"
                } transition-all duration-200 ease-in-out`}
              >
                <li>
                  <Link
                    to="/software-development"
                    className="block px-4 py-2 hover:bg-gray-600 rounded-md transition-colors"
                    onClick={() => {
                      closeSubMenu();
                      toggleMenu();
                    }}
                  >
                    Software Development
                  </Link>
                </li>
                <li>
                  <Link
                    to="/website-development"
                    className="block px-4 py-2 hover:bg-gray-600 rounded-md transition-colors"
                    onClick={() => {
                      closeSubMenu();
                      toggleMenu();
                    }}
                  >
                    Website Development
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/#business-development"
                    className="block px-4 py-2 hover:bg-gray-600 rounded-md transition-colors"
                    onClick={() => {
                      closeSubMenu();
                      toggleMenu();
                    }}
                  >
                    Business Development
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    to="/#digital-advertising"
                    className="block px-4 py-2 hover:bg-gray-600 rounded-md transition-colors"
                    onClick={() => {
                      closeSubMenu();
                      toggleMenu();
                    }}
                  >
                    Digital Advertising
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    to="/#local-seo"
                    className="block px-4 py-2 hover:bg-gray-600 rounded-md transition-colors"
                    onClick={() => {
                      closeSubMenu();
                      toggleMenu();
                    }}
                  >
                    Local SEO
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="/cloud-cyber-security"
                    className="block px-4 py-2 hover:bg-gray-600 rounded-md transition-colors"
                    onClick={() => {
                      closeSubMenu();
                      toggleMenu();
                    }}
                  >
                    Cloud & Cyber Security
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/team"
                className="block lg:inline-block hover:text-gray-400 p-2 lg:p-0 transition-colors"
                onClick={toggleMenu}
              >
                Team
              </Link>
            </li>
            <li>
              <Link
                to="/career"
                className="block lg:inline-block hover:text-gray-400 p-2 lg:p-0 transition-colors"
                onClick={toggleMenu}
              >
                Career
              </Link>
            </li>
            <li>
              <Link
                to="#industries"
                className="block lg:inline-block hover:text-gray-400 p-2 lg:p-0 transition-colors"
                onClick={toggleMenu}
              >
                Industries
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="block lg:inline-block hover:text-gray-400 p-2 lg:p-0 transition-colors"
                onClick={toggleMenu}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;