import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./components/Main";
import ContactUs from "./components/ContactUs";
import Team from "./components/Team";
import SoftwareDevelopment from "./components/SoftwareDevelopment";
import WebsiteDevelopment from "./components/WebsiteDevelopment";
import BusinessDevelopment from "./components/BusinessDevelopment";
import DigitalAdvertising from "./components/DigitalAdvertising";
import LocalSEO from "./components/LocalSEO";
import CloudCyberSecurity from "./components/CloudCyberSecurity";
import Career from "./components/Career";
import ApplyJob from "./components/ApplyJob";

const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/team" element={<Team />} />
            <Route
              path="/software-development"
              element={<SoftwareDevelopment />}
            />
            <Route
              path="/website-development"
              element={<WebsiteDevelopment />}
            />
            <Route
              path="/business-development"
              element={<BusinessDevelopment />}
            />
            <Route
              path="/digital-advertising"
              element={<DigitalAdvertising />}
            />
            <Route path="/local-seo" element={<LocalSEO />} />
            <Route
              path="/cloud-cyber-security"
              element={<CloudCyberSecurity />}
            />
            <Route path="/career" element={<Career />} />
            <Route path="*" element={<h1>Not Found</h1>} />
            <Route path="/about" element={<h1>About</h1>} />
            <Route path="/applyjob" element={<ApplyJob />} />

            {/* Add more routes here if needed */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
