import React from "react";
import { motion } from "framer-motion";

const services = [
  {
    title: "Software Development",
    description:
      "Setting a remarkable standard for unparalleled excellence in software development, Orbit 25 (OB-25) showcases exceptional skill in creating cutting-edge and flawlessly integrated digital solutions.",
    gradient: "from-blue-500 via-teal-500 to-green-500",
    link: "/software-development", // Add your specific link here
  },
  {
    title: "Website Development",
    description:
      "Unleashing unlimited business potential, we expertly harness the dynamic power of website development to achieve a competitive edge and phenomenal success in the online realm.",
    gradient: "from-purple-500 via-pink-500 to-red-500",
    link: "/website-development", // Add your specific link here
  },
  {
    title: "Business Tech Support",
    description:
      "Empowering your business with unparalleled tech support, ensuring seamless operations and maximizing efficiency to drive your success.",
    gradient: "from-yellow-400 via-orange-500 to-red-500",
    link: "/business-tech-support", // Add your specific link here
  },
  {
    title: "Tech Consultancy",
    description:
      "Providing expert guidance and strategic insights to navigate the evolving tech landscape, helping you leverage technology for sustainable growth and innovation.",
    gradient: "from-green-500 via-teal-400 to-blue-500",
    link: "/tech-consultancy", // Add your specific link here
  },
];

const BenefitsSection = () => (
  <section className="py-12 px-6 bg-gray-100">
    <h2 className="text-4xl font-bold mb-8 text-center text-gray-800">
      Benefits to Activate the Faster & Reliable Technology in Your Business
    </h2>
    <p className="text-lg text-center text-gray-700 mb-8">
      Unlocking Your Competitive Advantage: Discover How Integrating Fast and Reliable Technology Solutions Can Revolutionize Your Business Operations
    </p>
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: false, margin: "-100px 0px" }}
      transition={{ duration: 0.8 }}
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 md:gap-12 mb-20"
    >
      {services.map((service, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false, margin: "-100px 0px" }}
          transition={{ duration: 0.8, delay: index * 0.1 }}
          whileHover={{ scale: 1.05, rotate: 2 }}
          className={`p-6 md:p-8 lg:p-10 rounded-xl shadow-lg bg-gradient-to-r ${service.gradient} text-white transform hover:-translate-y-3 hover:shadow-2xl transition-all duration-300 ease-out`}
        >
          <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">{service.title}</h3>
          <p className="text-base md:text-lg mb-4">{service.description}</p>
          <a href={service.link} className="inline-block px-4 py-2 bg-white text-gray-800 rounded-full shadow-md hover:bg-gray-100 transition-all duration-300">
            View More
          </a>
        </motion.div>
      ))}
    </motion.div>
  </section>
);

export default BenefitsSection;
